import { useEffect, useState } from "react"

export const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false)

    useEffect(() => {
        if (element.current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setState(entry.isIntersecting)
                },
                { rootMargin }
            )

            element.current && observer.observe(element.current)

            return () => element.current && observer.unobserve(element.current)
        }
    }, [])

    return isVisible
}
