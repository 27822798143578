import { css } from "@emotion/react"
import Link from "next/link"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { imageBuilder } from "../../../config/sanity"
import { Asset } from "../../components/Asset"
import { Button, ButtonType } from "../../components/Button"
import { Footer } from "../../components/Footer"
import { Image } from "../../components/Image"
import { Main } from "../../components/Main"
import { Modal } from "../../components/Modal"
import Play from "../../components/Play"
import { Section } from "../../components/Section"
import { SeeForYourself } from "../../components/SeeForYourself"
import { Title, Title2, Title3, Title4 } from "../../components/Title"
import { Video } from "../../components/Video"
import { useIntersection } from "../../hooks/useIntersection"
import { analytics } from "@shiftx/analytics"
import { greaterThan, lessThan } from "../../utils/breakpoints"
import { RichText } from "../Case"
import { getGradient } from "../ProductPage"

export const HighlightSection = ({
    title,
    children,
    asset,
    color,
    isOdd = false,
}) => {
    return (
        <div
            css={css`
                margin-top: var(--section-margin);
                overflow-x: hidden;
            `}
        >
            <Section
                css={css`
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
                    align-items: center;
                    grid-gap: var(--grid-gap);
                `}
            >
                <div
                    css={css`
                        flex: 1;
                    `}
                >
                    {title && <Title4>{title}</Title4>}

                    {children}
                </div>

                <div
                    css={css`
                        ${isOdd &&
                        greaterThan(0)`
                            grid-column-start: 1;
                            grid-row: 1;
                            display: flex;
                            justify-content: flex-end;
                            min-width: 0;
                            flex-direction: column;
                            align-items: flex-end;
                        `}
                    `}
                >
                    <div
                        css={css`
                            width: 800px;
                            ${getGradient(color)}
                            overflow: hidden;
                            padding: 80px;
                            border-radius: 16px;

                            ${lessThan(0)`width: auto; padding: 24px;`}
                        `}
                    >
                        {asset?.[0] && <Asset asset={asset[0]} />}
                    </div>
                </div>
            </Section>
        </div>
    )
}

export const HomePage = ({ content }) => {
    const [selectedCase, setCase] = useState(content.cases?.[0]?._id ?? null)
    const video = useRef()
    const poster = content?.video?.poster
        ? imageBuilder.image(content.video.poster)?.url()
        : null

    const [showVideo, setShowVideo] = useState(false)
    const inViewport = useIntersection(video, "0px 0px -250px 0px") // Trigger as soon as the element becomes visible

    useEffect(() => {
        if (inViewport) {
            if (video.current && video.current.paused) {
                video.current.play()
            }
        }
    }, [inViewport])

    useEffect(() => {
        const listener = event => {
            if (event.key === "Escape") {
                setShowVideo(false)
            }
        }

        if (showVideo) {
            document.addEventListener("keydown", listener)
        }

        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [showVideo])

    return (
        <Fragment>
            <Main
                css={css`
                    margin-bottom: 0;
                `}
            >
                <Section
                    css={css`
                        max-width: 900px;
                    `}
                >
                    <Title
                        css={css`
                            text-align: center;
                        `}
                    >
                        {content.title}
                    </Title>

                    {content.tagline && (
                        <h2
                            css={css`
                                text-align: center;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 32px;
                                max-width: 600px;
                                margin: 0 auto;
                                margin-top: 24px;
                            `}
                        >
                            {content.tagline}
                        </h2>
                    )}

                    <div
                        css={css`
                            text-align: center;
                            margin-top: 34px;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            flex-direction: column;

                            ${greaterThan(0)`
                                flex-direction: row;
                            `};
                        `}
                    >
                        <Link href="/signup">
                            <Button
                                variant={ButtonType.Primary}
                                onClick={() => {
                                    analytics.track("Get started", {
                                        source: "frontpage",
                                    })
                                }}
                            >
                                Get started for free
                            </Button>
                        </Link>
                        <a
                            css={css`
                                display: flex;
                                align-items: center;
                                margin-top: 16px;

                                ${greaterThan(0)`
                                    margin-top: 0;
                                    margin-left: 24px;
                                `};

                                :hover {
                                    color: var(--brand-base);
                                }
                            `}
                            onClick={event => {
                                event.preventDefault()
                                setShowVideo(true)
                                analytics.track("Learn more", {
                                    source: "frontpage",
                                })
                            }}
                            href={content.cta.link}
                        >
                            <Play
                                css={css`
                                    margin-right: 12px;
                                `}
                            />
                            {content.cta.label}
                        </a>
                    </div>
                </Section>

                {showVideo && (
                    <Modal
                        onClose={() => {
                            setShowVideo(false)
                        }}
                        css={css`
                            width: 80%;
                        `}
                    >
                        <iframe
                            src={`${content.cta.link}?autoplay=1`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            css={css`
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                            `}
                        ></iframe>
                    </Modal>
                )}

                <Section
                    css={css`
                        max-width: 1100px;
                        margin-top: var(--section-margin-inside);
                    `}
                >
                    <Video
                        ref={video}
                        src={
                            content.video.video?.derived?.[0].url ||
                            content.video.video.secure_url
                        }
                        poster={poster}
                        asset={content.video.video}
                        css={css`
                            box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
                            border-radius: 8px;
                        `}
                    />
                </Section>

                <Section
                    css={css`
                        margin-top: var(--section-margin-inside);
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <div
                        css={css`
                            flex: 1;
                            display: grid;
                            grid-gap: 2rem;
                            grid-template-columns: repeat(
                                auto-fit,
                                minmax(8rem, 1fr)
                            );

                            ${lessThan(0)`
                                grid-template-columns: repeat(2,77px);
                                flex-grow: unset;
                            `}
                        `}
                    >
                        {content.logos.map((image, index) => {
                            return (
                                <Image
                                    key={index}
                                    image={image.image}
                                    alt={image?.alt}
                                />
                            )
                        })}
                    </div>
                </Section>

                <Section
                    css={css`
                        margin-top: var(--section-margin);
                    `}
                >
                    <Title2
                        css={css`
                            text-align: center;
                            max-width: 70%;
                            margin: 0 auto;
                        `}
                    >
                        {content.featureTitle}
                    </Title2>
                    <div
                        css={css`
                            margin-top: var(--section-margin-inside);
                            display: grid;
                            grid-gap: var(--grid-gap);
                            grid-template-columns: repeat(
                                auto-fit,
                                minmax(18rem, 1fr)
                            );
                        `}
                    >
                        {content.keyfeature.map((feature, index) => {
                            return (
                                <div
                                    key={index}
                                    css={css`
                                        background: rgba(0, 0, 0, 0.03);
                                        border-radius: 12px;
                                        padding: 40px;

                                        p {
                                            margin: 0;
                                        }
                                    `}
                                >
                                    <div
                                        css={css`
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 50%;
                                            margin-bottom: 40px;
                                            ${getGradient(feature.color)};
                                            place-items: center;
                                            display: grid;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                width: 20px;
                                                height: 20px;
                                            `}
                                        >
                                            <Image image={feature.asset[0]} />
                                        </div>
                                    </div>

                                    <Title4
                                        css={css`
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        {feature.title}
                                    </Title4>

                                    <div>
                                        <RichText content={feature.content} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Section>

                <div
                    css={css`
                        background-color: rgba(0, 0, 0, 0.03);
                    `}
                >
                    <Section
                        css={css`
                            margin-top: var(--section-margin);
                        `}
                    >
                        <div
                            css={css`
                                display: grid;
                                align-items: center;
                                grid-template-columns: 2fr 1fr;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                                margin-bottom: var(--section-margin-small);
                                padding: 24px 0;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    flex-wrap: wrap;
                                `}
                            >
                                {content.cases.map((usecase, index) => {
                                    return (
                                        <div
                                            key={index}
                                            css={css`
                                                cursor: pointer;
                                                color: rgba(0, 0, 0, 0.6);
                                                margin-right: 48px;

                                                :hover {
                                                    color: var(--fg-high);
                                                }

                                                ${selectedCase ===
                                                    usecase._id &&
                                                css`
                                                    border-bottom: 1px solid
                                                        #000;
                                                    padding-bottom: 8px;
                                                    color: var(--fg-high);
                                                `};
                                            `}
                                            onClick={() => {
                                                setCase(usecase._id)
                                            }}
                                        >
                                            {usecase.customername}
                                        </div>
                                    )
                                })}
                            </div>

                            <Link href="/customers" passHref>
                                <a
                                    css={css`
                                        color: rgba(0, 0, 0, 0.6);
                                        justify-self: flex-end;
                                        ${lessThan(0)`display: none`};

                                        :hover {
                                            color: var(--brand-base);
                                        }
                                    `}
                                >
                                    View all use cases {"->"}
                                </a>
                            </Link>
                        </div>

                        <div
                            css={css`
                                margin-bottom: 40px;
                                padding-bottom: 40px;
                            `}
                        >
                            {content.cases.map((usecase, index) => {
                                return (
                                    <div
                                        key={index}
                                        css={css`
                                            display: none;
                                            max-width: 820px;
                                            margin: 0 auto;
                                            align-items: center;

                                            ${selectedCase === usecase._id &&
                                            css`
                                                display: grid;
                                                grid-template-columns: repeat(
                                                    auto-fit,
                                                    minmax(10rem, 1fr)
                                                );
                                                grid-gap: var(--grid-gap);
                                            `}
                                        `}
                                    >
                                        <div>
                                            {usecase.quote.quoteImage && (
                                                <div
                                                    css={css`
                                                        border-radius: 50%;
                                                        overflow: hidden;
                                                        width: 180px;
                                                        height: 180px;
                                                        margin-bottom: 16px;
                                                    `}
                                                >
                                                    <Image
                                                        image={
                                                            usecase.quote
                                                                .quoteImage
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            css={css`
                                                grid-column: span 2;
                                            `}
                                        >
                                            <div>
                                                <div
                                                    css={css`
                                                        font-weight: 500;
                                                    `}
                                                >
                                                    {usecase.quote.quoteName}
                                                </div>
                                                <div
                                                    css={css`
                                                        font-weight: 500;
                                                        font-size: 14px;
                                                        line-height: 28px;
                                                        color: rgba(
                                                            0,
                                                            0,
                                                            0,
                                                            0.6
                                                        );
                                                    `}
                                                >
                                                    {usecase.quote.quoteTitle}
                                                </div>
                                            </div>

                                            <blockquote
                                                css={css`
                                                    font-style: italic;
                                                    font-weight: 600;
                                                    font-size: 24px;
                                                    line-height: 40px;
                                                    margin: 0;
                                                    margin-top: 24px;
                                                `}
                                            >
                                                "{usecase.quote.quote}"
                                            </blockquote>
                                            <Link
                                                passHref
                                                href={`/customers/${usecase.slug.current}`}
                                            >
                                                <a
                                                    css={css`
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 28px;
                                                        color: var(--fg-high);
                                                        margin-top: 24px;
                                                        display: inline-block;

                                                        :hover {
                                                            color: var(
                                                                --brand-base
                                                            );
                                                        }
                                                    `}
                                                >
                                                    Read the case {"->"}
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Section>
                </div>

                {content.usp.map((feature, index) => {
                    const isOdd = index % 2 == 0

                    return (
                        <HighlightSection
                            key={index}
                            title={feature.title}
                            asset={feature.asset}
                            color={feature.color}
                            isOdd={isOdd}
                        >
                            <Title3
                                css={css`
                                    margin-top: 16px;
                                `}
                            >
                                <RichText content={feature.content} />
                            </Title3>
                        </HighlightSection>
                    )
                })}

                <Section
                    css={css`
                        margin-top: var(--section-margin);
                    `}
                >
                    <Title2
                        css={css`
                            margin-bottom: var(--section-margin-inside);
                            text-align: center;
                        `}
                    >
                        {content.icpTitle}
                    </Title2>

                    <div
                        css={css`
                            display: grid;
                            grid-gap: var(--grid-gap);
                            grid-template-columns: repeat(
                                auto-fit,
                                minmax(14rem, 1fr)
                            );
                        `}
                    >
                        {content.icp.map((icp, index) => {
                            return (
                                <div key={index}>
                                    <div
                                        css={css`
                                            width: 180px;
                                            height: 180px;
                                            border-radius: 50%;
                                            margin: 0 auto;
                                            margin-bottom: 40px;
                                            ${getGradient(icp.color)};
                                            display: grid;
                                            place-items: center;
                                        `}
                                    >
                                        {icp.asset && (
                                            <div
                                                css={css`
                                                    width: 120px;
                                                    height: 120px;
                                                `}
                                            >
                                                <Image image={icp.asset[0]} />
                                            </div>
                                        )}
                                    </div>

                                    <Title4
                                        css={css`
                                            text-align: center;
                                            margin-bottom: 16px;
                                        `}
                                    >
                                        {icp.title}
                                    </Title4>
                                    <RichText
                                        content={icp.content}
                                        css={css`
                                            text-align: center;
                                            p {
                                                margin: 0;
                                            }
                                        `}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Section>

                <SeeForYourself />
            </Main>
            <Footer
                css={css`
                    border: 0;
                `}
            />
        </Fragment>
    )
}
